import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import CooperationProcedure from "../components/CooperationProcedure";
import Reference from "../components/Reference";
import FAQ from "../components/FAQ";
import Form from "../components/Form";
import Loading from "../components/Loading";

// Queries
import { GET_SERVICES } from "../components/Queries";

const Service = () => {
  const { service } = useParams();

  const { loading, data } = useQuery(GET_SERVICES, {
    variables: { url: service },
  });

  if (loading) return <Loading />;

  const serviceData = data.pravniPodsluzby.data[0].attributes;
  console.log(serviceData);
  return (
    <>
      <SEO
        title={`${serviceData.hlavni_nadpis} • Vladimíra Čapková`}
        pageURL={service}
      />
      <section className="subpages-heading">
        <div className="container">
          <h1>{serviceData.hlavni_nadpis}</h1>
          <p className="dark">{serviceData.podnadpis}</p>
          <div style={{ textAlign: "center", marginTop: "2.4em" }}>
            <Link to="#kontakt" className="button-fill-dark">
              {serviceData.hlavni_tlacitko}
            </Link>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row" style={{ justifyContent: "flex-start" }}>
            {serviceData.popis_podsluzby.map((subService, idx) => (
              <div key={idx} className="card card-dark col">
                <h3 className="light">{subService.nadpis}</h3>
                <p className="light" style={{ marginTop: "1.5em" }}>
                  {subService.popis}
                </p>
              </div>
            ))}
          </div>
          <div style={{ textAlign: "center", marginTop: "2.4em" }}>
            <Link to="#kontakt" className="button-fill-dark">
              CTA
            </Link>
          </div>
        </div>
      </section>
      <CooperationProcedure />
      <section>
        <FAQ />
      </section>
      <section>
        <div className="container">
          <Reference />
        </div>
      </section>
      <section>
        <div className="container">
          <Form />
        </div>
      </section>
    </>
  );
};

export default Transition(Service);
