import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

import arrow from "../assets/images/icons/arrow-full.svg";

const faqs = [
  {
    otazka: "Otázka č.1",
    odpoved:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras elementum. Duis viverra diam non justo. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
  },
  {
    otazka: "Otázka č.1",
    odpoved:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras elementum. Duis viverra diam non justo. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
  },
  {
    otazka: "Otázka č.1",
    odpoved:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras elementum. Duis viverra diam non justo. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
  },
];

const FAQ = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (idx) => {
    if (active === idx) {
      setActive(null);
    } else {
      setActive(idx);
    }
  };

  return (
    <>
      <div className="faq | container">
        <h2 style={{ textAlign: "center" }}>Možná se Vám ještě honí hlavou</h2>
        <div className="faq-card-container">
          {faqs.map((faq, idx) => (
            <AccordionItem
              key={idx}
              id={idx}
              heading={faq.otazka}
              text={faq.odpoved}
              active={active}
              handleToggle={handleToggle}
            />
          ))}
        </div>
        <div style={{ textAlign: "center", marginTop: "4em" }}>
          <Link to="" className="button-fill-dark">CTA</Link>
        </div>
      </div>
    </>
  );
};

const AccordionItem = ({ id, heading, text, handleToggle, active }) => {
  const contentEl = useRef(null);

  return (
    <>
      <div
        className="faq-card"
        onClick={() => handleToggle(id)}
      >
        <div className="faq-header">
          <h4 className="disable-select">{heading}</h4>
          <ReactSVG src={arrow} style={active === id ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }} />
        </div>
        <div
          ref={contentEl}
          className={`collapse ${active === id ? "show" : ""}`}
          style={
            active === id
              ? { height: contentEl.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <p className="dark disable-select">{text}</p>
        </div>
      </div>
    </>
  );
};

export default FAQ;
