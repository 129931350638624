import { motion } from "framer-motion";
import { ReactSVG } from "react-svg";

//Icons
import loading from "../assets/images/icons/loading.svg";

const Loading = () => {
  return (
    <motion.div
      className="slide"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.6, ease: [0.6, 0.51, 0.51, 0.98] }}
    >
      <ReactSVG src={loading} />
    </motion.div>
  );
};

export default Loading;
