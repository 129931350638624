import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion, useAnimationControls } from "framer-motion";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import ChangeDate from "../components/ChangeDate";
import { FromNothing } from "../components/Animations";

// Icons
import rightUpArrowIcon from "../assets/images/icons/arrow-up-right.svg";

const GET_CATEGORIES = gql`
  query GetCategories {
    katagorieClankus(pagination: { limit: -1 }) {
      data {
        attributes {
          nazev_kategorie
        }
      }
    }
  }
`;

const GET_BLOG_POSTS = gql`
  query GetBlogPosts($page: Int, $pageSize: Int, $category: String) {
    blogoveClanky(
      pagination: { page: $page, pageSize: $pageSize }
      filters: {
        katagorie_clanku: { nazev_kategorie: { contains: $category } }
      }
    ) {
      data {
        attributes {
          nadpis
          popisek_clanku
          url_clanku
          doba_cteni
          createdAt
          nahledova_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          katagorie_clanku {
            data {
              attributes {
                nazev_kategorie
              }
            }
          }
        }
      }
    }
  }
`;

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [activeCategory, setActiveCategory] = useState("Vše");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const blogPostsAnimation = useAnimationControls();

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_CATEGORIES);

  const {
    loading: blogLoading,
    error: blogError,
    data: blogData,
    fetchMore,
  } = useQuery(GET_BLOG_POSTS, {
    variables: {
      page: 1,
      pageSize: pageSize,
      category: selectedCategory,
    },
  });

  useEffect(() => {
    if (page > 1) {
      fetchMore({
        variables: { page: page },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.blogoveClanky.data.length < pageSize) {
            setHasMore(false);
          }
          return Object.assign({}, prev, {
            blogoveClanky: {
              __typename: prev.blogoveClanky.__typename,
              data: [
                ...prev.blogoveClanky.data,
                ...fetchMoreResult.blogoveClanky.data,
              ],
            },
          });
        },
      });
    }
  }, [page, fetchMore]);

  useEffect(() => {
    if (!blogLoading && !categoriesLoading && blogData) {
      blogPostsAnimation.set({
        opacity: 0,
        y: 50,
      });
      
      blogPostsAnimation.start({
        opacity: 1,
        y: 0,
        transition: { duration: .4, delay: .2 }
      });
    }
  }, [blogLoading, categoriesLoading, blogData, blogPostsAnimation]);

  const showCategory = async (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    blogPostsAnimation.start({
      opacity: 0,
      transition: { duration: 0.3 },
    });

    const toShow = e.target.innerText;

    setActiveCategory(toShow);

    setTimeout(() => {
      setSelectedCategory(toShow === "Vše" ? undefined : toShow);
    }, 500);
  };

  if (categoriesLoading && blogLoading) return "Loading...";
  // if (blogLoading) return <p>Loading blog...</p>;

  if (categoriesError)
    return <p>Něco se pokazilo, zkuste to prosím později Kategorie.</p>;
  if (blogError) {
    return <p>Něco se pokazilo, zkuste to prosím později Blog.</p>;
  }

  const blogPosts = !blogLoading && blogData.blogoveClanky.data;
  const categories = categoriesData.katagorieClankus.data;

  return (
    <>
      <SEO
        title="Blog • Vladimíra Čapková"
        pageURL="https://vladimiracapkova.cz/blog"
      />
      <section className="subpages-heading">
        <div className="container">
          <h1>
            <FromNothing top="-7%">Blog</FromNothing>
          </h1>
          <p className="dark">
            <FromNothing top="0" delay=".1">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
              elementum. Duis viverra diam non justo. Integer rutrum, orci
              vestibulum ullamcorper
            </FromNothing>
          </p>
        </div>
      </section>
      <section style={{ marginTop: "0" }}>
        <div className="categories-container | container">
          <div className="categories-content">
            <span
              className={activeCategory === "Vše" ? "active" : ""}
              onClick={showCategory}
            >
              Vše
            </span>
            {categories.map((category, idx) => (
              <span
                key={idx}
                className={
                  activeCategory === category.attributes.nazev_kategorie
                    ? "active"
                    : ""
                }
                onClick={showCategory}
              >
                {category.attributes.nazev_kategorie}
              </span>
            ))}
          </div>
        </div>
        <motion.div
          key={selectedCategory}
          animate={blogPostsAnimation}
          className="blog-posts | container"
        >
          <div className="row">
            {blogPosts && (
              <InfiniteScroll
                dataLength={blogPosts.length}
                next={() => setPage((prevPage) => prevPage + 1)}
                hasMore={hasMore}
                loader={<h4>Načítání...</h4>}
              >
                {blogPosts.map((blogPost, idx) => (
                  <div className="col" key={idx}>
                    <Link to={blogPost.attributes.url_clanku}>
                      <div className="blog-post-card">
                        <div className="blog-image">
                          <img
                            src={`${process.env.REACT_APP_CMS_URL}${blogPost.attributes.nahledova_fotografie.data.attributes.url}`}
                            alt={
                              blogPost.attributes.nahledova_fotografie.data
                                .attributes.alternativeText
                            }
                          />
                          <div className="blog-banner row">
                            <div>
                              <h5>Vladimíra Čapková</h5>
                              <p>
                                <ChangeDate
                                  date={blogPost.attributes.createdAt}
                                />{" "}
                                • {blogPost.attributes.doba_cteni} min čtení
                              </p>
                            </div>
                            <div>
                              <p>
                                {
                                  blogPost.attributes.katagorie_clanku.data[0]
                                    .attributes.nazev_kategorie
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <h2>{blogPost.attributes.nadpis}</h2>
                        <p className="not-complete-text">
                          {blogPost.attributes.popisek_clanku}
                        </p>
                        <button
                          className="wannabe-link"
                          style={{
                            display: "flex",
                            gap: ".6em",
                            marginTop: "1.2em",
                          }}
                        >
                          Přečíst článek <ReactSVG src={rightUpArrowIcon} />
                        </button>
                      </div>
                    </Link>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default Transition(Blog);
