const ChangeDate = ({date}) => {
  
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();

  const formatedDate = `${day}.${month}.${year}`;
  
  return formatedDate;
};

export default ChangeDate;