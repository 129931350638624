import React, { useState, useEffect, useCallback } from "react";
import { Link, useResolvedPath, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Icons
import houseIcon from "../assets/images/icons/house.svg";
import hamburgerIcon from "../assets/images/icons/hamburger-menu.svg";
import closeIcon from "../assets/images/icons/close-menu.svg";

// Logo
import logo from "../assets/images/logo/logo-white-mini.svg";

const links = [
  { to: "/", text: "Úvod" },
  { to: "/pravni-sluzby", text: "Právní služby" },
  { to: "/o-mne", text: "O mně" },
  { to: "/blog", text: "Blog" },
  { to: "/kontakt", text: "Kontakt" },
];

const Navbar = () => {
  const [subLinks, setSubLinks] = useState([]);
  const location = useLocation();

  const [isInitialMount, setIsInitialMount] = useState(true);
  const [navClass, setNavClass] = useState("mobile-nav");
  const [modalBackgroundClass, setModalBackgroundClass] =
    useState("modal-background");

  // Funkce pro zobrazení menu u mobilních zařízení
  const updateMenu = useCallback(() => {
    if (navClass.includes("open")) {
      setNavClass("mobile-nav");
      setModalBackgroundClass("modal-background");
      document.body.classList.remove("frozen");
    } else {
      setNavClass("mobile-nav open");
      setModalBackgroundClass("modal-background open");
      document.body.classList.add("frozen");
    }
  }, [navClass]);

  // Drobečková navigace
  const updateSubLinks = useCallback(() => {
    const splitUrl = location.pathname.split("/").filter(Boolean);
    const matchingLink = links.find((link) => link.to === `/${splitUrl[0]}`);

    if (location.pathname !== "/" && matchingLink) {
      let newLinks = [{ to: matchingLink.to, text: matchingLink.text }];

      if (splitUrl[1]) {
        const observer = new MutationObserver((mutations, obs) => {
          const h1 = document.querySelector("h1");
          if (h1) {
            setSubLinks([
              ...newLinks,
              { to: location.pathname, text: h1.textContent },
            ]);
            obs.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      } else {
        setSubLinks(newLinks);
      }
    } else {
      setSubLinks([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
      updateSubLinks();
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
        updateSubLinks();
      }, 600);
    }
  }, [location.pathname, isInitialMount, updateSubLinks]);

  return (
    <>
      <header>
        <Link to="/" className="nav-logo">
          <ReactSVG src={logo} />
        </Link>
        <nav>
          <ul className="nav-ul">
            {links.map((link, idx) => (
              <CustomLink key={idx} to={link.to}>
                {link.text}
              </CustomLink>
            ))}
          </ul>
        </nav>
        <div className="nav-actions">
          <div className="hamburger-menu" onClick={updateMenu}>
            <ReactSVG src={hamburgerIcon} />
          </div>
          <Link to="/kontakt" className="button-fill-light">
            Nezávazná konzultace
          </Link>
        </div>
      </header>
      {subLinks.length > 0 && (
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">
                <ReactSVG src={houseIcon} className="icon" />
              </Link>
            </li>
            {subLinks.map((subLink, idx) => (
              <React.Fragment key={idx}>
                <span style={{ color: "#D3D8E0" }}>/</span>
                <li>
                  <Link to={subLink.to} className="breadcrumb-link">
                    <span>{subLink.text}</span>
                  </Link>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
      <nav className={navClass}>
        <div className="close" onClick={updateMenu}>
          <ReactSVG src={closeIcon} />
        </div>
        <ul className="nav-ul">
          {links.map((link, idx) => (
            <CustomLink key={idx} to={link.to} onClick={updateMenu}>
              {link.text}
            </CustomLink>
          ))}
        </ul>
      </nav>
      <div className={modalBackgroundClass} onClick={updateMenu}></div>
    </>
  );
};

const CustomLink = ({ to, children, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  const location = useLocation();
  const isActive = location.pathname === resolvedPath.pathname || location.pathname.startsWith(`${resolvedPath.pathname}/`);

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
};

export default Navbar;
