import { Link } from "react-router-dom";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";

const CookiePolicy = () => {
  return (
    <>
      <SEO
        title="Zásady cookies • Vladimíra Čapková"
        pageURL="zasady-cookie"
      />
      <section style={{ marginTop: "8em" }}>
        <div className="container">
          <h1>Zásady cookies</h1>
          <p style={{ marginTop: "1em" }}>
            Na těchto webových stránkách pracuji se soubory cookie. Více se
            dočtete níže.
          </p>
          <h5 style={{ marginTop: "1.7em" }}>
            <strong>Kontaktní údaje:</strong>
          </h5>
          <p>
            E-mail:{" "}
            <Link to="mailto:example@domain.com" style={{ color: "#338bdb" }}>
              <u>example@domain.com</u>
            </Link>
          </p>
          <p>
            Tel.č.:{" "}
            <Link to="tel:+420777777777" style={{ color: "#338bdb" }}>
              <u>+420777777777</u>
            </Link>
          </p>
          <h3 style={{ marginTop: "1.7em" }}>Vítejte</h3>
          <p className="dark" style={{ marginBottom: "1em" }}>
            Tento web, který spravuji, shromažďuje určité informace
            prostřednictvím souborů cookie a dalších online technologií. Vaše
            soukromí je pro mě velmi důležité, a proto bych vám ráda vysvětlila,
            jak používám a chráním informace, které o vás shromažďuji.
            Respektuji vaše soukromí a dodržuji osvědčené postupy v souladu s
            platnými zákony na ochranu soukromí, včetně GDPR.
          </p>
          <p className="dark" style={{ marginBottom: "1em" }}>
            Tyto zásady používání souborů cookie vám přiblíží, jakým způsobem
            pracuji s informacemi, které o vás na tomto webu získávám, a stanoví
            vaše práva na ochranu soukromí.
          </p>
          <p className="dark">
            Používáním tohoto webu souhlasíte s tím, že mohu umisťovat soubory
            cookie, jak je popsáno níže, a souhlasíte se shromažďováním,
            sdílením a používáním vašich údajů pro personalizaci služeb.
          </p>
          <h3 style={{ marginTop: "1.7em" }}>Co jsou to cookie?</h3>
          <p className="dark">
            Cookie jsou textové soubory, které tato webová stránka odešle do
            prohlížeče, respektive vašeho zařízení, ze kterého tyto webové
            stránky prohlížíte (tedy například telefon, tablet, počítač).
            Umožňují mi analyzovat, jak používáte tento web, a díky tomu mohu
            zlepšovat vaše uživatelské prostředí. Více informací o jednotlivých
            typech cookie najdete níže.
          </p>
          <h3 style={{ marginTop: "1.7em" }}>Proč používám soubory cookie?</h3>
          <p className="dark">
            Díky souborům cookie mohu zlepšovat svůj web a tím i vaši zkušenost
            při jeho procházení. Pomocí cookie vám mohu umožnit rychlejší a
            efektivnější přístup k obsahu a nabídnout vám více personalizované
            služby. Tyto soubory mi pomáhají rozpoznat vás při každé návštěvě a
            „zapamatovat si“ vaše preference. Také mi umožňují sestavovat
            statistiky, díky kterým lépe chápu, jak web používáte, a mohu jej
            podle toho vylepšovat. Ukládáním vašich preferencí vám mohu
            poskytnout pozitivnější zážitek při procházení a nabídnout
            personalizovaná nastavení. Shromažďuji také údaje o vašem
            prohlížení, abych vám mohla zobrazit ten nejrelevantnější obsah.
          </p>
          <h3 style={{ marginTop: "1.7em" }}>
            Jak mohu odmítnout nebo odstranit soubory cookie?
          </h3>
          <p className="dark">
            Většina internetových prohlížečů je zpočátku nastavena tak, aby
            automaticky podporovala soubory cookie. Můžete však změnit nastavení
            svého prohlížeče tak, aby odmítal všechny soubory cookie, určité
            typy souborů cookie nebo konkrétní soubory cookie. Svůj prohlížeč
            můžete také nastavit tak, aby vás upozornil a požádal o povolení,
            když jsou do vašeho zařízení odesílány soubory cookie. Dále můžete
            uložené cookie kdykoli smazat. Pokud k prohlížení a přístupu na tyto
            webové stránky používáte různá zařízení, možná budete muset
            zajistit, aby byl každý prohlížeč na každém zařízení upraven tak,
            aby vyhovoval vašim preferencím pro soubory cookie.
          </p>
          <p className="dark">
            Používáním těchto webových stránek bez vymazání některých nebo všech
            souborů cookie se bude mít za to, že souhlasíte s umístěním souborů
            cookie do jakéhokoli zařízení používaného k přístupu na tyto
            stránky.
          </p>
          <h3 style={{ marginTop: "1.7em" }}>
            Kde mohu získat více informací?
          </h3>
          <p className="dark">
            Další informace o používání souborů cookie, včetně toho, jak
            zjistit, jaké soubory cookie byly nastaveny a jak soubory cookie
            vypnout, naleznete na stránkách{" "}
            <Link to="https://allaboutcookies.org" style={{ color: "#338bdb" }}>
              <u>allaboutcookies.org</u>
            </Link>{" "}
            nebo{" "}
            <Link to="https://aboutcookies.org" style={{ color: "#338bdb" }}>
              <u>aboutcookies.org</u>
            </Link>
            .
          </p>
          <h3 style={{ marginTop: "1.7em" }}>Jaké typy cookie používám?</h3>
          <ul>
            <li>
              <span>Technické, funkční</span>{" "}
              <span>
                – ty jsou nutné ke správnému fungování webových stránek a všech
                funkcí na nich.
              </span>
            </li>
            <li>
              <span>Analytické</span>{" "}
              <span>
                - tyto cookies mi pomáhají analyzovat, jak fungují tyto webové
                stránky z hlediska chování návštěvníků a podle toho web případně
                přizpůsobovat a měnit.
              </span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Transition(CookiePolicy);
