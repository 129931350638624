import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import Reference from "../components/Reference";
import Loading from "../components/Loading";

// Queries
import { GET_HOME } from "../components/Queries";

// Icons
import Arrow from "../assets/images/icons/arrow.svg";

const Home = () => {
  const { loading, data } = useQuery(GET_HOME);

  if (loading) return <Loading />;

  const introductionData = data.uvod.data.attributes;
  const services = data.pravniPodsluzby.data;
  const blogData = data.blogoveClanky.data;

  return (
    <>
      <SEO title="Vladimíra Čapková • Advokát" pageURL="" />
      <section className="home-introduction">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>{introductionData.hlavni_nadpis}</h1>
              <p className="dark">{introductionData.podnadpis}</p>
              <button
                onClick={() => {
                  document.querySelector(".target-group").scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                className="button-fill-light"
              >
                Zjistěte více
              </button>
            </div>
          </div>
        </div>
        <div className="introduction-image">
          <img src={`${process.env.REACT_APP_CMS_URL}${introductionData.uvodni_fotografie.data.attributes.url}`} alt={introductionData.uvodni_fotografie.data.attributes.alternativeText} />
        </div>
      </section>
      <section className="target-group | light-background">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>{introductionData.spoluprace_nadpis}</h2>
            </div>
            <div className="col">
              <p className="dark">{introductionData.spoluprace_text}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h2 style={{ textAlign: "center", marginBottom: "1em" }}>
          Právní služby
        </h2>
        <div className="home-services | container">
          <div className="row">
            {services.slice(0, 5).map((service, idx) => (
              <div key={idx} className="col card card-border not-complete-text">
                <h3>{service.attributes.hlavni_nadpis}</h3>
                <p className="not-complete-text">
                  {service.attributes.podnadpis}
                </p>
                <Link
                  to={`/pravni-sluzby/${service.attributes.url}`}
                  className="button-fill-shade"
                >
                  Zjistěte více <ReactSVG src={Arrow} />
                </Link>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                gap: "1.1em",
              }}
              className="col"
            >
              {services.slice(5, 8).map((service, idx) => (
                <Link
                  key={idx}
                  to={`/pravni-sluzby/${service.attributes.url}`}
                  style={{ color: "#000" }}
                >
                  <h3>
                    <u>{service.attributes.hlavni_nadpis}</u>
                  </h3>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "3em" }}>
          <Link to="/kontakt" className="button-fill-dark">
            Chci konzultaci
          </Link>
        </div>
      </section>
      <section className="home-about | light-background">
        <div className="container">
          <div className="row">
            <div className="col">
              <img
                src={`${process.env.REACT_APP_CMS_URL}${introductionData.o_mne_fotografie.data.attributes.url}`}
                alt={introductionData.o_mne_fotografie.data.attributes.alternativeText}
              />
            </div>
            <div className="col">
              <h2>{introductionData.o_mne_nadpis}</h2>
              <p>{introductionData.o_mne_text}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <h2>Co říkají moji klienti</h2>
          <Reference />
        </div>
      </section>
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="sticky | col">
              <div>
                <h2>
                  Nejnovější články
                </h2>
                <Link to="/blog" className="button-fill-dark">
                  Všechny články
                </Link>
              </div>
            </div>
            <div className="col">
              <ul>
                {blogData.map((blogPost, idx) => (
                  <React.Fragment key={idx}>
                    <li>
                      <Link to={`/blog/${blogPost.attributes.url_clanku}`}>
                        <div className="row reverse">
                          <div className="col">
                            <img
                              src={`${process.env.REACT_APP_CMS_URL}${blogPost.attributes.nahledova_fotografie.data.attributes.url}`}
                              alt={
                                blogPost.attributes.nahledova_fotografie.data
                                  .attributes.alternativeText
                              }
                            />
                          </div>
                          <div className="col">
                            <span style={{ color: "#3f434a" }}>
                              {
                                blogPost.attributes.katagorie_clanku.data[0]
                                  .attributes.nazev_kategorie
                              }
                            </span>
                            <h3>{blogPost.attributes.nadpis}</h3>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="primary-cta light-background">
            <h2>Získejte nezávaznou konzultaci</h2>
            <p className="dark">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
              elementum. Duis viverra diam non justo. Lorem ipsum dolor sit
              amet.
            </p>
            <Link to="/kontakt" className="button-fill-dark">
              Chci nezávaznou konzultaci
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transition(Home);
