import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useQuery } from "@apollo/client";

// Components
import Loading from "../components/Loading";

// Queries
import { GET_REFERENCE } from "../components/Queries";

// Styles
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

const Reference = () => {
  const { loading, data } = useQuery(GET_REFERENCE);

  if (loading) return <Loading />;

  const references = data.references.data;

  return (
    <>
      <Splide
        options={{
          type: "loop",
          gap: "3em",
          perPage: 3,
          focus: 0,
          drag: false,
          lazyLoad: "nearby",
          autoplay: true,
          autoScroll: {
            pauseOnHover: true,
            pauseOnFocus: false,
            speed: 8,
          },
          breakpoints: {
            980: {
              perPage: 2,
            },
            888: {
              perPage: 1,
            },
          },
        }}
        aria-label="reference carousel"
      >
        {references.map((reference, index) => (
          <SplideSlide key={index} className="card card-shade">
            <div>
              <p className="dark">{reference.attributes.text}</p>
              <h4>{reference.attributes.jmeno_klienta}</h4>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};

export default Reference;
