import { Helmet } from 'react-helmet-async';

export default function SEO(prop){
  
  const { title, pageURL } = prop

  return(
    <Helmet>
      <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}${pageURL}`}></link>
      <title>{title}</title>
    </Helmet>      
  );
}