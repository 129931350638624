import { gql } from "@apollo/client";

const GET_ABOUT = gql`
  query GetAbout {
    oMne {
      data {
        attributes {
          hlavni_nadpis
          podnadpis
        }
      }
    }
  }
`;

export default GET_ABOUT;