import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import CooperationProcedure from "../components/CooperationProcedure";
import Reference from "../components/Reference";
import FAQ from "../components/FAQ";
import Form from "../components/Form";
import Loading from "../components/Loading";

// Queries
import { GET_LAWYER_SERVICES } from "../components/Queries";

// Icons
import Arrow from "../assets/images/icons/arrow.svg";
import ArrowDown from "../assets/images/icons/arrow-down-right.svg";

const services = [
  { heading: "Občanské právo", link: "/pravni-sluzby/obcanske-pravo" },
  { heading: "Rodinné právo", link: "/pravni-sluzby/rodinne-pravo" },
  { heading: "Obchodní právo", link: "/pravni-sluzby/obchodni-pravo" },
  { heading: "Práva nemovitostí", link: "/pravni-sluzby/prava-nemovitosti" },
  { heading: "Právo správní", link: "/pravni-sluzby/pravo-spravni" },
  { heading: "Smluvní agenda", link: "/pravni-sluzby/smluvni-agenda" },
  {
    heading: "Exekuce a insolvence",
    link: "/pravni-sluzby/exekuce-a-insolvence",
  },
  {
    heading: "Zastoupení v trestním řízení",
    link: "/pravni-sluzby/zastoupeni-v-trestnim-rizeni",
  },
];

const LawyerServices = () => {
  const { loading, data } = useQuery(GET_LAWYER_SERVICES);

  const [hoverClass, setHoverClass] = useState("services-offer-service");

  const handleMouseEnter = (event) => {
    const mouseY = event.clientY;
    const elementY = event.target.getBoundingClientRect().top;

    if (mouseY < elementY + event.target.offsetHeight / 2) {
      setHoverClass("services-offer-service hover-from-top");
    } else {
      setHoverClass("services-offer-service hover-from-bottom");
    }
  };

  if (loading) return <Loading />;

  const servicesData = data.obecnePravniSluzba.data.attributes;

  return (
    <>
      <SEO
        title="Právní služby • Vladimíra Čapková"
        pageURL="pravni-sluzby"
      />
      <section className="subpages-heading">
        <div className="container">
          <h1>{servicesData.hlavni_nadpis}</h1>
          <p>{servicesData.podnadpis}</p>
          <div className="services-links">
            <div className="services-row">
              {services.slice(0, 5).map((service, index) => (
                <Link to={service.link} key={index}>
                  <div className="services-card | button-fill-shade">
                    <h5>{service.heading}</h5>
                    <ReactSVG src={ArrowDown} />
                  </div>
                </Link>
              ))}
            </div>
            <div className="services-row">
              {services.slice(5, 8).map((service, index) => (
                <Link to={service.link} key={index}>
                  <div className="services-card | button-fill-shade">
                    <h5>{service.heading}</h5>
                    <ReactSVG src={ArrowDown} />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <CooperationProcedure ctaLink="#test" ctaText="Proč si mě vybrat" />
      </section>
      <section className="reason-to-choose-container">
        <div className="container">
          <h2>{servicesData.konkurencni_vyhoda_nadpis}</h2>
          <p style={{ color: "#3F434A", maxWidth: "500px" }}>
            {servicesData.konkurencni_vyhoda_popis}
          </p>
          <div className="reason-to-choose | row">
            {servicesData.konkurenci_vyhody.map((advantage, idx) => (
              <div className="card card-dark col" key={idx}>
                <ReactSVG
                  src={
                    process.env.REACT_APP_CMS_URL +
                    advantage.ikona.data.attributes.url
                  }
                />
                <h3 className="light">{advantage.nadpis}</h3>
                <p className="light" style={{ marginTop: "1.5em" }}>
                  {advantage.popis}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="services-offer | container">
          <h2 className="light">Co Vám mohu nabídnout</h2>
          <p className="light">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
            elementum. Duis viverra diam non justo. Integer Lorem ipsum dolor
            sit amet, consectetuer adipiscing elit. Cras elementum. Duis viverra
            diam non justo. Integer
          </p>
          <div className="services-offer-container">
            {services.map((service, index) => (
              <Link
                to={service.link}
                key={index}
                onMouseEnter={handleMouseEnter}
              >
                <div className={hoverClass}>
                  <h4 className="light">{service.heading}</h4>
                  <div className="icon">
                    <ReactSVG src={Arrow} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="#kontakt" className="button-fill-light">
              Domluvit schůzku
            </Link>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <Reference />
        </div>
      </section>
      <section>
        <div className="mini-about | container">
          <h2>O mně</h2>
          <p className="dark">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
            elementum. Duis viverra diam non justo. Lorem ipsum dolor sit amet.
          </p>
          <Link to="#kontakt" className="button-fill-dark">
            CTA
          </Link>
        </div>
      </section>
      <section>
        <FAQ />
      </section>
      <section>
        <div className="container">
          <Form />
        </div>
      </section>
    </>
  );
};

export default Transition(LawyerServices);
