import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useQuery } from "@apollo/client";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import ChangeDate from "../components/ChangeDate";
import Loading from "../components/Loading";

// Queries
import { GET_BLOG_POST, GET_RECOMENDET_BLOG_POST } from "../components/Queries";

// Icons
import arrowIcon from "../assets/images/icons/arrow.svg";
import rightUpArrowIcon from "../assets/images/icons/arrow-up-right.svg";

const BlogPost = () => {
  const { blogPost } = useParams();
  console.log(blogPost);

  const [postContent, setPostContent] = useState([]);
  const [activeHeading, setActiveHeading] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const containerRef = useRef(null);

  const {
    loading: loadingPost,
    error: errorPost,
    data: dataPost,
  } = useQuery(GET_BLOG_POST, {
    variables: { urlClanku: blogPost },
  });

  const {
    loading: loadingRecPost,
    error: errorRecPost,
    data: dataRecPost,
  } = useQuery(GET_RECOMENDET_BLOG_POST, {
    skip: !dataPost,
    variables: {
      category:
        dataPost?.blogoveClanky.data[0].attributes.katagorie_clanku.data[0]
          .attributes.nazev_kategorie,
      urlClanku: blogPost,
    },
  });

  // Obsah článku
  useEffect(() => {
    if (containerRef.current) {
      const headingElements = containerRef.current.querySelectorAll("h2, h3");
      const headingTexts = Array.from(headingElements).map((heading) => ({
        text: heading.textContent,
        type: heading.tagName.toLowerCase(),
      }));

      setPostContent(headingTexts);
    }

    if (!loadingPost && !loadingRecPost) {
      setDataLoaded(true);
    }
  }, [loadingPost, loadingRecPost]);

  useEffect(() => {
    if (dataLoaded && containerRef.current) {
      const headingElements = containerRef.current.querySelectorAll("h2, h3");

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveHeading(entry.target.id);
            }
          });
        },
        {
          rootMargin: "-300px",
        }
      );

      headingElements.forEach((el) => observer.observe(el));

      return () => {
        headingElements.forEach((el) => observer.unobserve(el));
      };
    }
  }, [dataLoaded, activeHeading]);

  if (loadingPost || loadingRecPost) return <Loading />;
  if (errorPost || errorRecPost)
    return <p>Něco se pokazilo, zkuste to prosím později.</p>;

  const singleBlogPost = dataPost.blogoveClanky.data[0].attributes;
  const recBlogPosts = dataRecPost.blogoveClanky.data;

  return (
    <>
      <SEO
        title={`${singleBlogPost.nadpis} • Vladimíra Čapková`}
        pageURL={`blog/${singleBlogPost.url_clanku}`}
      />
      <section className="blog-post-wrapper | subpages-heading left-heading">
        <div className="container">
          <Link to="/blog" className="back-to-all">
            <ReactSVG src={arrowIcon} />
            Všechny články
          </Link>
          <div className="blog-post-info">
            <ChangeDate date={singleBlogPost.createdAt} /> •{" "}
            {singleBlogPost.doba_cteni} min čtení
          </div>
          <h1>{singleBlogPost.nadpis}</h1>
          <p className="dark">{singleBlogPost.popisek_clanku}</p>
          <img
            src={`${process.env.REACT_APP_CMS_URL}${singleBlogPost.nahledova_fotografie.data.attributes.url}`}
            alt={
              singleBlogPost.nahledova_fotografie.data.attributes
                .alternativeText
            }
            className="introduction-image"
          />
        </div>
      </section>
      <section className="blog-post-content">
        <div className="container">
          <div className="row">
            <div className="content_toc-outer | col">
              <div className="content_toc-wrapper">
                {postContent && (
                  <div className="content_toc">
                    <h5>Článek obsahuje</h5>
                    {postContent.map((postHeading, idx) => {
                      const id = postHeading.text
                        .toLowerCase()
                        .replace(/[^a-z0-9]+/g, "-");

                      return (
                        <HashLink
                          key={idx}
                          to={`#${id}`}
                          className={`${
                            postHeading.type === "h3" ? "toc-h3" : ""
                          } ${activeHeading === id ? "is-active" : ""}`}
                          smooth
                        >
                          {postHeading.text}
                        </HashLink>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="content_toc-gradient-overlay"></div>
            </div>
            <div className="blog-post | col" ref={containerRef}>
              <BlocksRenderer
                content={singleBlogPost.obsah}
                blocks={{
                  heading: ({ children, level }) => {
                    switch (level) {
                      case 1:
                        return <h1>{children}</h1>;
                      case 2:
                        return (
                          <h2
                            id={children[0].props.text
                              .toLowerCase()
                              .replace(/[^a-z0-9]+/g, "-")}
                          >
                            {children}
                          </h2>
                        );
                      case 3:
                        return (
                          <h3
                            id={children[0].props.text
                              .toLowerCase()
                              .replace(/[^a-z0-9]+/g, "-")}
                          >
                            {children}
                          </h3>
                        );
                      case 4:
                        return <h4>{children}</h4>;
                      case 5:
                        return <h5>{children}</h5>;
                      case 6:
                        return <h6>{children}</h6>;
                      default:
                        return <p>{children}</p>;
                    }
                  },
                  link: ({ children, url }) => (
                    <Link to={url} target="_blank" rel="noopener noreferrer">
                      {children}
                    </Link>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="light-background">
        <div className="next-blog-posts container">
          <h2>Související články</h2>
          <div className="row">
            {recBlogPosts.map((post, idx) => (
              <div className="col" key={idx}>
                <Link to={`/blog/${post.attributes.url_clanku}`}>
                  <div className="blog-post-card">
                    <div className="blog-image">
                      <img
                        src={`${process.env.REACT_APP_CMS_URL}${post.attributes.nahledova_fotografie.data.attributes.url}`}
                        alt={
                          post.attributes.nahledova_fotografie.data.attributes
                            .alternativeText
                        }
                      />
                      <div className="blog-banner row">
                        <div>
                          <h5>Vladimíra Čapková</h5>
                          <p>
                            <ChangeDate date={post.attributes.createdAt} /> •{" "}
                            {post.attributes.doba_cteni} min čtení
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              post.attributes.katagorie_clanku.data[0]
                                .attributes.nazev_kategorie
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <h2>{post.attributes.nadpis}</h2>
                    <p className="not-complete-text">
                      {post.attributes.popisek_clanku}
                    </p>
                    <button
                      className="wannabe-link"
                      style={{
                        display: "flex",
                        gap: ".6em",
                        marginTop: "1.2em",
                      }}
                    >
                      Přečíst článek <ReactSVG src={rightUpArrowIcon} />
                    </button>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="primary-cta dark-background">
            <h2>Získejte nezávaznou konzultaci</h2>
            <p className="dark">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
              elementum. Duis viverra diam non justo. Lorem ipsum dolor sit
              amet.
            </p>
            <Link to="/kontakt" className="button-fill-dark">
              Chci nezávaznou konzultaci
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transition(BlogPost);
