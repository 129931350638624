import { gql } from "@apollo/client";

// Home
const GET_HOME = gql`
  query GetHome {
    uvod {
      data {
        attributes {
          hlavni_nadpis
          podnadpis
          uvodni_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          spoluprace_nadpis
          spoluprace_text
          o_mne_nadpis
          o_mne_text
          o_mne_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
    pravniPodsluzby {
      data {
        attributes {
          hlavni_nadpis
          podnadpis
          url
        }
      }
    }
    blogoveClanky(
      pagination: { page: 1, pageSize: 3 }
      sort: "createdAt:desc"
    ) {
      data {
        attributes {
          nadpis
          url_clanku
          nahledova_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          katagorie_clanku {
            data {
              attributes {
                nazev_kategorie
              }
            }
          }
        }
      }
    }
  }
`;

// Lawyer services
const GET_LAWYER_SERVICES = gql`
  query GetLawyerServices {
    obecnePravniSluzba {
      data {
        attributes {
          hlavni_nadpis
          podnadpis
          konkurencni_vyhoda_nadpis
          konkurencni_vyhoda_popis
          konkurenci_vyhody {
            ikona {
              data {
                attributes {
                  url
                }
              }
            }
            nadpis
            popis
          }
        }
      }
    }
  }
`;

// Services
const GET_SERVICES = gql`
  query GeServices($url: String!) {
    pravniPodsluzby(filters: { url: { eq: $url } }) {
      data {
        attributes {
          hlavni_nadpis
          podnadpis
          hlavni_tlacitko
          popis_podsluzby {
            nadpis
            popis
          }
          popis_podsluzby_tlacitko
        }
      }
    }
  }
`;

// Blog Post
const GET_BLOG_POST = gql`
  query GetClanek($urlClanku: String!) {
    blogoveClanky(filters: { url_clanku: { eq: $urlClanku } }) {
      data {
        attributes {
          createdAt
          doba_cteni
          nadpis
          popisek_clanku
          nahledova_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          obsah
          katagorie_clanku {
            data {
              attributes {
                nazev_kategorie
              }
            }
          }
        }
      }
    }
  }
`;

const GET_RECOMENDET_BLOG_POST = gql`
  query GetRecomendetBlogPost($category: String!, $urlClanku: String!) {
    blogoveClanky(
      pagination: { page: 1, pageSize: 3 }
      filters: {
        katagorie_clanku: { nazev_kategorie: { contains: $category } }
        and: { url_clanku: { ne: $urlClanku } }
      }
    ) {
      data {
        attributes {
          createdAt
          doba_cteni
          nadpis
          popisek_clanku
          url_clanku
          nahledova_fotografie {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          katagorie_clanku {
            data {
              attributes {
                nazev_kategorie
              }
            }
          }
        }
      }
    }
  }
`;

// Reference
const GET_REFERENCE = gql`
  query GetReference {
    references {
      data {
        attributes {
          jmeno_klienta
          text
        }
      }
    }
  }
`;

export {
  GET_HOME,
  GET_LAWYER_SERVICES,
  GET_SERVICES,
  GET_BLOG_POST,
  GET_RECOMENDET_BLOG_POST,
  GET_REFERENCE,
};
