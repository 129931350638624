import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import Form from "../components/Form";

// Icons
import point from "../assets/images/icons/point.svg";
import email from "../assets/images/icons/email.svg";
import mobile from "../assets/images/icons/mobile.svg";

const Constact = () => {
  return (
    <>
      <SEO
        title="Kontakt • Vladimíra Čapková"
        pageURL="kontakt"
      />
      <section className="subpages-heading">
        <div className="contact | container">
          <div className="row">
            <div className="col">
              <h1>Kontakt</h1>
              <hr className="contact-line" />
              <div className="contact-information | row">
                <div className="col">
                  <ul>
                    <li>
                      <ReactSVG src={point} />
                      <span className="contact-link">
                        Prešovská 170/5, <br />
                        301 00 Plzeň 3
                      </span>
                    </li>
                    <li>
                      ičo:
                      <span className="contact-link">755213341</span>
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>
                      <ReactSVG src={email} />
                      <Link to="mailto:email" className="contact-link">
                        e-mail
                      </Link>
                    </li>
                    <li>
                      <ReactSVG src={mobile} />
                      <Link to="tel:+420777777777" className="contact-link">
                        +420 777 777 777
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <Form />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <iframe
            title="Mapa s umístěním kanceláře Vladimíry Čapkové"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d412.8407384154195!2d13.375513702814521!3d49.746366887204836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470af17af82104fb%3A0x41e96caf8f386758!2zSlVEci4gSW5nLiBWbGFkaW3DrXJhIMSMYXBrb3bDoSwgYWR2b2vDoXRrYQ!5e0!3m2!1scs!2scz!4v1723029454357!5m2!1scs!2scz"
            width="100%"
            height="450"
            style={{ border: 0, borderRadius: "1em" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Transition(Constact);
