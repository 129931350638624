import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Icons
import logo_white from "../assets/images/logo/logo-white.svg";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="no-margin">
        <div className="container">
          <div className="footer-top | row">
            <div>
              <ReactSVG src={logo_white} className="footer-logo" />
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
                elementum. Duis viverra diam non justo. Lorem ipsum dolor sit.
              </p>
            </div>
            <div className="footer-links | row">
              <div>
                <h4>Rychlé odkazy</h4>
                <ul>
                  <li>
                    <Link to="/pravni-sluzby">Právní služby</Link>
                  </li>
                  <li>
                    <Link to="/o-mne">O mně</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4>Právní služby</h4>
                <ul>
                  <li>
                    <Link to="/pravni-sluzby/obcanske-pravo">
                      Občanské právo
                    </Link>
                  </li>
                  <li>
                    <Link to="/pravni-sluzby/rodinne-pravo">Rodinné právo</Link>
                  </li>
                  <li>
                    <Link to="/pravni-sluzby/obchodni-pravo">
                      Obchodní právo
                    </Link>
                  </li>
                  <li>
                    <Link to="/pravni-sluzby">Všechny služby</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4>Kontakt</h4>
                <ul>
                  <li>
                    <Link to="">E-mail</Link>
                  </li>
                  <li>
                    <Link to="">Telefon</Link>
                  </li>
                  <li>Adresa</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-bottom | row">
            <p>
              Copyright © {year} <Link to="/">Vladimíra Čapková</Link>. All
              Rights Reserved.
            </p>
            <p>
              Code by{" "}
              <Link
                to="https://dominikplecity.cz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dominik Plecitý
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
