import { useQuery } from "@apollo/client";
import { ReactSVG } from "react-svg";

// Components
import Transition from "../components/Transition";
import SEO from "../components/SEO";
import Form from "../components/Form";
import Reference from "../components/Reference";
import { FromNothing } from "../components/Animations";
import Loading from "../components/Loading";

// Queries
import GET_ABOUT from "../data/queries/about";

// Icons
import Handshake from "../assets/images/icons/handshake.svg";
import Check from "../assets/images/icons/check.svg";
import Ruler from "../assets/images/icons/ruler.svg";

const About = () => {
  const { loading, data } = useQuery(GET_ABOUT);

  if (loading) return <Loading />;

  const aboutData = data.oMne.data.attributes;

  return (
    <>
      <SEO title="O mně • Vladimíra Čapková" pageURL="o-mne" />
      <section className="subpages-heading">
        <div className="container">
          <h1>
            <FromNothing top="-1%">{aboutData.hlavni_nadpis}</FromNothing>
          </h1>
          <div className="row">
            <hr />
            <p>{aboutData.podnadpis}</p>
          </div>
        </div>
      </section>
      <section className="competitive-advantage | light-background">
        <div className="container">
          <h2 style={{ textAlign: "center" }}>
            Nadstandardní hodnoty, na kterých mi záleží
          </h2>
          <div className="row">
            <div className="col">
              <ReactSVG src={Handshake} />
              <div>
                <h3>Osobní přístup</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
                </p>
              </div>
            </div>
            <div className="col">
              <ReactSVG src={Ruler} />
              <div>
                <h3>Vše ušité na míru</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
                </p>
              </div>
            </div>
            <div className="col">
              <ReactSVG src={Check} />
              <div>
                <h3>Spravedlivá cena</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-reference">
        <div className="container">
          <h2>Pomohla jsem již několika klientům</h2>
          <div className="about-reference-contect">
            <Reference />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <Form />
        </div>
      </section>
    </>
  );
};

export default Transition(About);
