const Form = ({ offer }) => {
  return (
    <>
      <div className="form-container">
        <form id="kontakt">
          {offer && <h3>{offer}</h3>}
          <div className="input-container">
            <label htmlFor="name">Jméno</label>
            <input type="text" id="name" name="name" placeholder=" " />
          </div>
          <div className="input-container">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" name="email" placeholder=" " />
          </div>
          <div className="input-container" style={{ marginTop: "1.3em" }}>
            <label htmlFor="message">Zpráva</label>
            <textarea
              id="message"
              name="message"
              rows="6"
              placeholder=" "
            ></textarea>
          </div>
          <button className="button-fill-light">Domluvit schůzku</button>
        </form>
      </div>
    </>
  );
};

export default Form;
