import { Link } from "react-router-dom";

const CooperationProcedure = ({ ctaText, ctaLink }) => {
  return (
    <>
      <div className="coop-procedure | container">
        <h2 style={{ textAlign: "center" }}>Postup spolupráce</h2>
        <div className="row" style={{ marginTop: "3em" }}>
          <div className="col">
            <div className="coop-procedure-step">
              <div className="ring">1</div>
              <h5>První krok</h5>
            </div>
          </div>
          <div className="col">
            <div className="coop-dots">
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
            </div>
          </div>
          <div className="col">
            <div className="coop-procedure-step">
              <div className="ring">2</div>
              <h5>Druhý krok</h5>
            </div>
          </div>
          <div className="col">
            <div className="coop-dots">
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
            </div>
          </div>
          <div className="col">
            <div className="coop-procedure-step">
              <div className="ring">3</div>
              <h5>Třetí krok</h5>
            </div>
          </div>
          <div className="col">
            <div className="coop-dots">
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
              <div className="coop-dot"></div>
            </div>
          </div>
          <div className="col">
            <div className="coop-procedure-step">
              <div className="ring">4</div>
              <h5>Čtvrtý krok</h5>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "3em" }}>
          <Link to={ctaLink} className="button-fill-dark">
            {ctaText}
          </Link>
        </div>
      </div>
    </>
  );
};

export default CooperationProcedure;
