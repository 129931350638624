import { motion } from "framer-motion";

const FromNothing = ({ children, top, delay }) => {
  return (
    <span
      style={{
        overflow: "hidden",
        display: "inline-block",
      }}
    >
      <motion.span
        style={{ display: "inline-block" }}
        initial={{
          y: "200%",
        }}
        animate={{
          y: top,
          transition: { ease: [1, 0.45, 0, 0.65], duration: 0.6, delay: delay },
        }}
      >
        {children}
      </motion.span>
    </span>
  );
};

export { FromNothing };
