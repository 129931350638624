import { Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AnimatePresence } from "framer-motion";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Pages
import Home from "./pages/Home";
import LawyerServices from "./pages/LawyerServices";
import Service from "./pages/Service";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import CookiePolicy from "./pages/CookiePolicy";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
//import CookieJar from "./components/CookieJar";

// Styles
import "./assets/styles/layout/layout.css";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_CMS_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_API_TOKEN;

  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : "",
    },
  };
});

// apollo client
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  const location = useLocation();

  return (
    <>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <Navbar />
          <AnimatePresence mode="wait" initial={false}>
            <Routes location={location} key={location.pathname}>
              <Route index element={<Home />} />
              <Route path="/pravni-sluzby" element={<LawyerServices />} />
              <Route path="/pravni-sluzby/:service" element={<Service />} />
              <Route path="/o-mne" element={<About />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:blogPost" element={<BlogPost />} />
              <Route path="/zasady-cookie" element={<CookiePolicy />} />
            </Routes>
          </AnimatePresence>
          {/*<CookieJar />*/}
          <Footer />
        </ApolloProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
